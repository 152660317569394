import './App.css';
import Main from './Dashboard/Main';

function App() {

  return (

    <div className="app">
      <main className="content">
        <Main />

      </main>
    </div>

  );
}

export default App;
