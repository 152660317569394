import React, { useState } from 'react';
import { AppBar, Toolbar, IconButton, Typography, Popover, Box, Button, Stack, Menu, MenuItem } from '@mui/material';
import { Menu as MenuIcon, Search as SearchIcon, AccountCircle, Settings } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import SearchBar from './SearchBar';
import Divider from '@mui/material/Divider';
import LogoutIcon from '@mui/icons-material/Logout';


// Define constants for styling
const APPBAR_DRAWER = 280;
const MOBILE_BAR = 64;
const DESKTOP_BAR = 92;
const HEADER_HEIGHT = 100;


const StyledPopover = styled(Popover)(({ theme }) => ({
    '& .MuiPaper-root': {
        backgroundColor: theme.palette.grey[800],
        color: theme.palette.common.white,
        borderRadius: theme.shape.borderRadius,
        minWidth: 180,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(2)
    },
    '& .MuiPopover-paper': {
        overflow: 'visible',
    }
}));


// Styling root divs
const BaseStyle = styled(AppBar)(({ theme }) => ({
    boxShadow: 'none',
    backdropFilter: 'blur(6px)',
    WebkitBackdropFilter: 'blur(6px)',
    backgroundColor: theme.palette.background.default,
    height: HEADER_HEIGHT,
    position: 'fixed',
    zIndex: 1000,
    width: '100%',
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
    minHeight: MOBILE_BAR,
    [theme.breakpoints.up('lg')]: {
        minHeight: DESKTOP_BAR,
        padding: theme.spacing(0, 5),
    },

}));

const NavBar = () => {
    const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up('md'));

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        // Implement your logout logic here
        console.log('User logged out');
        handleClose();
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <BaseStyle>
            <ToolbarStyle>
                {isLargeScreen ? (
                    <Stack
                        direction='row'
                        justifyContent="space-between"
                        alignItems='center'
                        sx={{
                            width: '100%',
                            paddingTop: '20px',
                            paddingBottom: '5px',
                            paddingLeft: '20px',
                            paddingRight: '10px',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <img src="/assets/amd_logo.png" alt="AMD Logo" style={{ height: 40 }} />
                            <Divider orientation="vertical" variant="middle" color="white" flexItem sx={{ height: 60, marginTop: '15px', marginRight: '10px', marginLeft: '10px' }} />
                            <Typography variant="h2" color="white">MI300 Series Device Lookup</Typography>
                        </Box>
                        <SearchBar />


                        <IconButton
                            aria-describedby={id}
                            sx={{ color: 'text.primary' }}
                            onClick={handleClick}
                        >
                            <MenuIcon />
                        </IconButton>
                        <StyledPopover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
  
                                <MenuItem onClick={handleLogout} sx={{ color: 'inherit', display: 'flex', alignItems: 'center' }}>
                                    Logout
                                    <IconButton size="small" color="inherit">
                                        <LogoutIcon />
                                    </IconButton>
                                </MenuItem>
                        </StyledPopover>
                    </Stack>
                ) : (
                    <Stack
                        direction='row'
                        justifyContent="space-between"
                        alignItems='center'
                        sx={{
                            width: '100%',
                            paddingTop: '20px',
                            paddingBottom: '20px',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <img src="/assets/amd_logo.png" alt="AMD Logo" style={{ height: 20 }} />
                            <Divider orientation="vertical" variant="middle" color="white" flexItem sx={{ height: 30, marginTop: '15px', marginRight: '10px', marginLeft: '10px' }} />
                            <Typography variant="h3" color="white">MI300 Series Device Lookup</Typography>
                        </Box>


                        <IconButton
                            aria-describedby={id}
                            sx={{ color: 'text.primary' }}
                            onClick={handleClick}
                        >
                            <MenuIcon />
                        </IconButton>
                        <StyledPopover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                           <MenuItem onClick={handleLogout} sx={{ color: 'inherit', display: 'flex', alignItems: 'center' }}>
                                    Logout
                                    <IconButton size="small" color="inherit">
                                        <LogoutIcon />
                                    </IconButton>
                                </MenuItem>
                        </StyledPopover>
                    </Stack>

                )}
            </ToolbarStyle>
        </BaseStyle>
    );
};

export default NavBar;
