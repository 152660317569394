import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import CircularProgress from '@mui/material/CircularProgress';
import {
    Stack, Button,
    ClickAwayListener,
    Typography,
} from "@mui/material";
import axios from 'axios';


const backend_base_url = process.env.REACT_APP_BACKEND_API_URL;

const fetchData = async (searchedSerial) => {
    try {
        
        const [response_json, response_excel] = await Promise.all([
            axios.get(`${backend_base_url}/api/STSProDataQuery/2d-pro-traceability-realtime`, {
              params: {
                'SerialNo': searchedSerial,
                'userID': 'donallim@amd.com',
                'ip': '60.123.40.5'
              }
            }),
            axios.get(`${backend_base_url}/api/STSProDataQuery/2d-pro-traceability-download`, {
              params: {
                'SerialNo': searchedSerial,
                'userID': 'donallim@amd.com',
                'ip': '60.123.40.5'
              },
              responseType: 'blob'
            })
        ]);

        return {
            jsonAPIData: response_json.data,
            excelAPIData: response_excel.data
          };

    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
};

const WhiteButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    color: "black", 
    '&:hover': {
        backgroundColor: '#7ad2fa', 
    },
}));

const SearchBox = styled('div')(({ theme, expanded }) => ({
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius * 3,
    boxShadow: theme.shadows[1],
    padding: theme.spacing(0.5, 1),
    width: '100%',
    height: '6ch'
}));

const SearchInput = styled(InputBase)(({ theme }) => ({
    marginLeft: theme.spacing(1),
    flex: 1,
    color: "black",
    fontWeight: 'bolder',
    fontSize: '18px'
}));

const BarcodeSearch = ({ onSearch, downloadSearch, barcodeSn, resetBarcodeSn }) => {
    const [open, setOpen] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [loading, setLoading] = useState(false);

    const [ip, setIP] = useState("");

    const getIPAddress = async () => {
        const res = await axios.get("https://api.ipify.org/?format=json");
        console.log(res.data);
        setIP(res.data.ip);
    };


    const handleClick = () => {
        setOpen(true);
    };

    const handleClickAway = () => {
        setOpen(false);
    };

    const handleTextChange = (event) => {
        setSearchText(event.target.value);
    };

    const handleSearch = async () => {
        console.log('Search Text:', searchText);
        setLoading(true);
        
        try {
            const data = await fetchData(searchText);
            console.log('Data from JSON API:', data.jsonAPIData);
            console.log('Data from EXCEL API:', data.excelAPIData);
            onSearch(data.jsonAPIData);
            downloadSearch(data.excelAPIData);
            resetBarcodeSn();
        } catch (error) {

        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getIPAddress();
    }, []);

    useEffect(() => {
        if (barcodeSn) {
            setSearchText(barcodeSn);
        }
    }, [barcodeSn]);

    useEffect(() => {
        if (barcodeSn && searchText) {
            handleSearch();
        }
    }, [searchText]);

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <Stack
                direction={{ xs: 'column', sm: 'row', md: 'row' }}
                justifyContent="space-between"
                spacing={2}
                sx={{
                    position: 'absolute',
                    top: { xs: '40%', sm: '50%' }, 
                    left: { xs: '50%', sm: '4%', md: '4%', lg: '4%', xl: '4%' }, 
                    transform: { xs: 'translate(-50%, -50%)', sm: 'none' }, 
                    width: { xs: '80%', sm: '75%', md: '50%', lg: '40%', xl: '30%' },
                    zIndex: 1,
                    textAlign: { xs: 'center', sm: 'left' } 
                }}
            >
                <SearchBox expanded={open}>
                    <SearchInput
                        placeholder="Input your barcode here ..."
                        value={searchText}
                        onChange={handleTextChange}
                        onClick={handleClick}
                    />
                </SearchBox>
                <WhiteButton
                    type='button'
                    variant="contained"
                    startIcon={loading ? <CircularProgress size={24} color="inherit" /> : <SearchIcon />}
                    disabled={loading}
                    onClick={handleSearch}
                    sx={{
                        borderRadius: 5,
                        width: { xs: '50%', sm: '30%' },
                        alignSelf: 'center',
                        '& .MuiCircularProgress-svg': {
                            color: 'white', // Color of CircularProgress
                        },
                    }}
                >
                    <Typography
                        variant='body1'
                        textAlign='center'
                        color="black"
                        fontWeight="bolder"
                        fontSize='large'
                        sx={{ textTransform: 'capitalize', color: 'inherit' }}
                    >
                        {loading ? 'Searching...' : 'Search'}
                    </Typography>
                </WhiteButton>
            </Stack>
        </ClickAwayListener>
    );
}

export default BarcodeSearch;
