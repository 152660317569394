import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Box,
  TableContainer,
  Table,
  IconButton,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  Button,
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import FileDownloadIcon from '@mui/icons-material/FileDownload';


const renderDetails = ({ details, backOnClick }) => {
  if (!details) {
    return null; // Render nothing if details are null
  }

  return (
    details.map((detail, i) => (
      <Card
        variant="outlined"
        sx={{
          height: '100%',
          width: '100%',
          backgroundColor: 'black',
          borderRadius: 8,
          borderColor: 'white',
          borderWidth: 3,
          marginBottom: 2,
          transition: 'transform 0.3s ease',
        }}
        key={i}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', mt: '1%', mr: '5%' }}>
          <Button
            variant="text"
            sx={{
              color: 'white',
              textDecoration: 'underline',
              fontSize: { xs: '1rem', sm: '1rem', md: '1rem', lg: '1.2rem', xl: '1.5rem' },
              textTransform: 'capitalize',
              '&:hover': {
                color: 'red',
              },
            }}
            onClick={backOnClick}
            endIcon={<CloseIcon />}
          >
            close
          </Button>
        </Box>

        <CardContent sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', paddingLeft: '5%' }}>
            <Typography
              variant="h6"
              sx={{
                fontWeight: 'bold',
                fontSize: { xs: '1rem', sm: '1rem', md: '0.7rem', lg: '1rem', xl: '1.2rem' }
              }}
            >
              {Object.keys(detail)[0]}: {detail[Object.keys(detail)[0]].value}
            </Typography>
            <Typography
              variant="h6"
              sx={{
                fontWeight: 'bold',
                fontSize: { xs: '1rem', sm: '1rem', md: '0.7rem', lg: '1rem', xl: '1.2rem' }
              }}
            >
              {Object.keys(detail)[1]}: {detail[Object.keys(detail)[1]].value}
            </Typography>
          </Box>
          <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', paddingTop: '5%' }}>
            <TableContainer sx={{ width: '100%' }}>
              <Table sx={{ width: '100%', "& td": { border: 0 } }}>
                <TableBody>
                  <TableRow>
                    <TableCell component="th"
                      scope="row"
                      sx={{
                        borderBottom: 'black',
                        borderRight: '2px solid white',
                        paddingRight: '3%',
                        paddingLeft: '3%',
                        width: '50%',
                        textAlign: 'left',
                        fontWeight: 'bold',
                        fontSize: { xs: '1rem', sm: '1rem', md: '0.7rem', lg: '1rem', xl: '1.2rem' }
                      }}>Type</TableCell>
                    {Object.keys(detail[Object.keys(detail)[2]].details[0]).map((key, q) => (
                      <TableCell sx={{
                        fontWeight: 'bold',
                        fontSize: { xs: '1rem', sm: '1rem', md: '0.7rem', lg: '1rem', xl: '1.2rem' },
                        paddingLeft: '3%',
                      }}
                        key={key}>{key}</TableCell>
                    ))}

                  </TableRow>

                  {Object.keys(detail).slice(2).map((index, j) => (
                    <React.Fragment key={j}>

                      <TableRow>
                        <TableCell component="th"
                          scope="row"
                          sx={{
                            borderBottom: 'black',
                            borderRight: '2px solid white',
                            paddingRight: '3%',
                            paddingLeft: '3%',
                            width: '50%',
                            textAlign: 'left',
                            fontWeight: 'bold',
                            fontSize: { xs: '1rem', sm: '1rem', md: '0.7rem', lg: '1rem', xl: '1.2rem' }
                          }}>{index}</TableCell>

                        {detail[index].details.map((item, k) => (
                          <React.Fragment key={k}>

                            {Object.values(item).map((val, l) => (
                              <TableCell sx={{
                                fontWeight: 'bold',
                                fontSize: { xs: '1rem', sm: '1rem', md: '0.7rem', lg: '1rem', xl: '1.2rem' },
                                paddingLeft: '3%',
                              }}
                                key={l}>
                                {val.value}
                              </TableCell>
                            ))}
                          </React.Fragment>
                        ))}

                        {detail[index].details.length === 0 && (
                          <TableCell colSpan={2} />
                        )}
                      </TableRow>
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </CardContent>
      </Card>
    ))
  );
};

const CardComponent = ({ items, onAddDetails, clearHighlight }) => {
  const [highlightedRow, setHighlightedRow] = useState(null);

  useEffect(() => {
    if (clearHighlight) {
      setHighlightedRow(null);
    }
  }, [clearHighlight]);

  const handleMouseClick = (index) => {
    setHighlightedRow(index);
  }

  const handleAddDetails = (item, index) => {
    onAddDetails(item.details);
    handleMouseClick(index);
  };

  return (
    <Card
      variant="outlined"
      sx={{
        height: '100%',
        width: '100%',
        backgroundColor: 'black',
        borderRadius: 8,
        borderColor: 'white',
        borderWidth: 3,
        marginBottom: 2
      }}>
      <CardContent sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
          <TableContainer sx={{ width: '100%' }}>
            <Table sx={{ width: '100%', "& td": { border: 0 } }}>
              <TableBody>
                {items.map((item, index) => (
                  <TableRow
                    key={index}
                    style={{ backgroundColor: highlightedRow === index ? 'red' : 'transparent' }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        borderBottom: 'black',
                        borderRight: '2px solid white',
                        paddingRight: '3%',
                        paddingLeft: '3%',
                        width: '50%',
                        textAlign: 'left',
                        fontWeight: 'bold',
                        fontSize: { xs: '1rem', sm: '1rem', md: '0.7rem', lg: '1rem', xl: '1.2rem' }
                      }}
                    >{item.key}</TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        fontSize: { xs: '1rem', sm: '1rem', md: '0.7rem', lg: '1rem', xl: '1.2rem' },
                        paddingLeft: '3%',
                      }}>{item.value}</TableCell>

                    {item.details && (
                      <TableCell>
                        <IconButton
                          onClick={
                            () => {
                              handleAddDetails(item, index)
                            }}
                          style={{
                            color: 'white',
                            backgroundColor: onAddDetails ? 'transparent' : 'white',
                            transition: 'background-color 0.3s ease',
                          }}
                          onMouseOver={(e) => {
                            e.currentTarget.style.color = 'red';
                          }}
                          onMouseOut={(e) => {
                            e.currentTarget.style.color = 'white';
                          }}
                        >
                          <AddCircleOutlineIcon />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </CardContent>

    </Card>
  );
};

const splitDataIntoChunks = (data) => {
  const entries = Object.entries(data.body)
    .filter(([key]) => key !== 'STATUS' && key !== 'ALLOW-COPY')
    .map(([key, value]) => ({ key, ...value }));

  const oamEntries = entries.filter(entry => entry.key.startsWith('OAM'));
  const otherEntries = entries.filter(entry => !entry.key.startsWith('OAM'));

  return [otherEntries, oamEntries];
};

const CardDetails = ({ searchResult, downloadResult }) => {
  // A FUNCTION TO DOWNLOAD {downloadResult}
  const handleDownload = async () => {
    try {
      alert('Downloading uploaded file...');
      const blob = new Blob([downloadResult], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'output.xlsx';
      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);

    } catch (error) {
      console.error('Error downloading file:', error);
      alert('Error downloading file. Please try again.');
    }
  }

  const [otherEntries, oamEntries] = searchResult ? splitDataIntoChunks(searchResult) : [[], []];

  const [additionalDetails, setAdditionalDetails] = useState([]);

  const [clearHighlightRow, setClearHighlightRow] = useState(false);

  const handleAddDetails = (details) => {
    setAdditionalDetails([details]);
    setClearHighlightRow(false);
  };

  const clearDetails = () => {
    setAdditionalDetails([]);
    setClearHighlightRow(true);
  }

  const isAdditionalDetailsPresent = additionalDetails.length > 0;

  return (
    <div>
      {searchResult && (
        <Box sx={{ flexGrow: 1, paddingTop: '2%', paddingRight: isAdditionalDetailsPresent ? '2%' : '5%', paddingLeft: isAdditionalDetailsPresent ? '2%' : '5%' }}>

          {searchResult.body['ALLOW-COPY'].value ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                mb: '2%',
                mr: '5%'
              }}>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  alignSelf: 'flex-end',
                  mt: 1,
                  textTransform: 'capitalize',
                  fontSize: '1rem'
                }}
                startIcon={<FileDownloadIcon />}
                onClick={handleDownload}
              >
                Download CSV
              </Button>
            </Box>
          ) : null}

          <Grid container spacing={isAdditionalDetailsPresent ? 5 : 10} justifyContent="center">
            {oamEntries.length === 0 ? (
              <Grid item xs={12} sm={12} md={6} lg={5} xl={5}>
                <CardComponent items={otherEntries} />
              </Grid>
            ) : (
              <>
                <Grid item xs={12} sm={12} md={isAdditionalDetailsPresent ? 4 : 6} lg={isAdditionalDetailsPresent ? 4 : 5} xl={isAdditionalDetailsPresent ? 4 : 5}>
                  <CardComponent items={otherEntries} />
                </Grid>
                <Grid item xs={12} sm={12} md={isAdditionalDetailsPresent ? 4 : 6} lg={isAdditionalDetailsPresent ? 4 : 5} xl={isAdditionalDetailsPresent ? 4 : 5}>
                  <CardComponent items={oamEntries} onAddDetails={handleAddDetails} clearHighlight={clearHighlightRow} />
                </Grid>
              </>
            )}
            {additionalDetails.length > 0 && (
              <Grid item xs={12} sm={12} md={4}>
                {renderDetails({ details: additionalDetails[0], backOnClick: clearDetails })}
              </Grid>
            )}
          </Grid>


        </Box>
      )}
    </div>
  );
};

export default CardDetails;
