import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import AppBar from '@mui/material/AppBar';
import SearchContent from '../components/SearchContent';
import DownloadApp from '../components/DownloadApp';
import {
  Box,
  Grid
} from "@mui/material";
import CardDetails from '../components/CardDetails';

// Styling
const HEADER_HEIGHT = 90;

const BaseStyle = styled('div')({
    display: 'flex',
    flexDirection: 'column', 
    minHeight: '100vh', 
    overflowY: 'auto',
});

const ContentContainer = styled('div')(({ theme }) => ({
  flexGrow: 1,
  paddingTop: HEADER_HEIGHT, 
  paddingBottom: theme.spacing(0),
  position: 'relative', 
}));


function Main() {
  const [searchResult, setSearchResult] = useState(null);
  const [downloadResult, setDownloadResult] = useState(null);
    return (
      <div>
        <AppBar
        position="fixed"
        sx={{
          boxShadow: 'none',
          backdropFilter: 'blur(6px)',
          WebkitBackdropFilter: 'blur(6px)',
          backgroundColor: 'transparent',
          height: HEADER_HEIGHT,
          zIndex: 1000,
          width: '100%',
        }}
      >
        <NavBar />
      </AppBar>
      <BaseStyle>
      <ContentContainer>
          <Grid container spacing={1}>
            <Grid item xs={12} lg={8}>
              <Box
                sx={{
                  position: 'absolute',
                  top: HEADER_HEIGHT, 
                  height: { xs: '300px', sm: '350px', md: '400px', lg: '440px', xl: '500px' },
                  width: '100%',
                  display: 'flex',
                  overflow: 'hidden',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <SearchContent setSearchResult={setSearchResult} setDownloadResult={setDownloadResult}/>
              </Box>
            </Grid>
            <Grid item xs={12} lg={12}>
              <Box
                sx={{
                  position: 'relative',
                  zIndex: 1, 
                  marginTop: { xs: '300px', sm: '350px', md: '400px', lg: '440px', xl: '500px' }, 
               
                }}
              >
                <CardDetails searchResult={searchResult} downloadResult={downloadResult} />
              </Box>
            </Grid>
            <Grid item xs={12} lg={12}>
              <Box
                sx={{
                  position: 'relative', 
                  zIndex: 1, 
                  marginTop: '100px' 
               
                }}
              >
                <DownloadApp />
              </Box>
            </Grid>
          </Grid>
        </ContentContainer>
        <Footer />
      </BaseStyle>
        </div>
    );
}

export default Main;
